import { Axios } from 'axios';

import { PaymentPage } from '@/apexModules/payment-page/types/paymentPage';
import { ECryptoNetworkSymbol } from '@/constants/chains';
import { EPaymentType } from '@/constants/enum';
import { EPresentmentCurrency, EPresentmentCurrencyUnits } from '@/constants/payment';
import { ECryptoCurrencySymbol } from '@/constants/tokens';
import { EConnectsStatus } from '@/dashboard/components/team-settings/constants';
import {
  IGetFraudReportRequest,
  IGetFraudReportResponse,
  IGetFraudReportsRequest,
  IGetFraudReportsResponse,
} from '@/dashboard/pages/fraud-reports/types/';
import { TPaymentPageNft } from '@/dashboard/pages/payment-details/types';
import { EStatusEnum as EIncomingStatus } from '@/dashboard/pages/payments/components/incoming';
import { EStatusEnum as EOutgoingStatus } from '@/dashboard/pages/payments/components/outgoing';
import { TPaymentPageSettlement } from '@/types/settlement';
import { trimObjValues } from '@/utils';
import { EConnectRelationships } from '@/utils/role';

import { merchantResolvedLock } from '../firebaseAuth';

import { createBaseAxios, IArrayData, IBaseTimeStamp, IPagingQuery } from './api-base';
import { EKycStatus } from './api-kyc';

// Common
export interface IBaseModel {
  id: string;
  createdAt: number;
  updatedAt?: number;
}
export interface IGenerateSignedUrlRequest {
  fileType: string;
}

export interface IGenerateSignedUrlResponse {
  signedUrl: string;
  uploadUrl: string;
}
export interface IInvoiceSub {
  amount: string | number;
  currency: ECryptoCurrencySymbol;
  recipientName: string;
  billingEmail: string;
  // dueDate: string;
  companyName: string;
  description: string;
  expireInDays: number;
}
export interface IInvoice extends IInvoiceSub, IBaseTimeStamp {
  id: string;
  referenceId: string;
  merchantId: string;
  paymentPage: string;
  paymentPageUrl: string;
  status: string;
}

export interface IProductSub {
  name: string;
  currency: ECryptoCurrencySymbol;
  amount: string | number;
  description: string;
  images?: string[];
  image?: string;
}

export interface IProduct extends IProductSub, IBaseTimeStamp {
  id: string;
  isNFT: boolean;
}

export interface ILineItems {
  product: string;
  quantity: number;
}
export interface IPaymentLinkSub {
  title: string;
  currency?: ECryptoCurrencySymbol;
  amount?: string | number;
  description?: string;
  successReturnUrl?: string;
  failReturnUrl?: string;
  lineItems?: ILineItems[];
}
export interface IPaymentLink extends IPaymentLinkSub, IBaseTimeStamp {
  id: string;
  merchantId: string;
}

export interface ISecret extends IBaseTimeStamp {
  id: string;
  status: string;
}

interface ILineItem {
  productId: string;
  name: string;
  currency: ECryptoCurrencySymbol;
  amount: number;
  price: number;
  quantity: number;
  description?: string;
  images?: string[];
}

export enum EPaymentPageStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
}

export interface IPaymentPage extends IBaseTimeStamp {
  id: string;
  merchant: BeamoNS.IMerchant;
  currency: ECryptoCurrencySymbol;
  amount: number;
  lineItems: ILineItem[];
  clientReferenceId?: string;
  successReturnUrl?: string;
  failReturnUrl?: string;
  url: string;
  clientSecret: string;
  status: EPaymentPageStatus;
  paymentId?: string;
  transactionNetwork?: ECryptoNetworkSymbol;
  transactionFromAddress?: string;
  transactionToken?: string;
  transactionAmount?: string;
  transactionHash?: string;
  isNFT: boolean;
  billingEmail?: string;
  paymentType?: EPaymentType;
  refundStatus?: string;
}

export interface IRiskEngineCheckLog {
  checkName: string;
  status: string;
}

export interface IPaymentDetail extends IBaseTimeStamp {
  id: string;
  token: ECryptoCurrencySymbol;
  network: ECryptoNetworkSymbol;
  billingEmail?: string;
  feeAmount: string;
  netAmount: string;
  grossAmount: string;
  status: string;
  transferEvmAddress?: string;
  transactionHash?: string;
  transactionGrossAmount?: string;
  walletName?: string;
  walletAddress?: string;
  currency?: string;
  pageId: string;
  clientReferenceId?: string;
  merchantId: string;
  type: EPaymentType;
  confirmedAt?: number;
  failureReason?: string;
  refundStatus?: string;
  riskEngineTraceId?: string;
  riskEngineCheckLogs?: IRiskEngineCheckLog[];
}

export interface ICheckoutPaymentData {
  checkoutPaymentId?: string;
  billingName?: string;
  bin?: string;
  last4?: string;
  ipAddress?: string;
  billingDescriptor?: string;
  billing_address?: {
    country?: string;
    zip?: string;
  };
  cardType?: string;
}

export interface IPaymentAttemptSiftEvent {
  score?: number;
  reasons?: { name: string; value: string }[];
}

export interface ICardPaymentAttempt {
  paymentType: EPaymentType.CARD_PAYMENT;
  currency?: EPresentmentCurrencyUnits;
  exchangeRate?: string;
  checkoutPaymentData?: ICheckoutPaymentData;
  siftEventData?: IPaymentAttemptSiftEvent;
}

export interface ITransferPaymentAttempt {
  paymentType: EPaymentType.TRANSFER_PAYMENT;
  network?: ECryptoNetworkSymbol;
  token: ECryptoCurrencySymbol;
  transferEvmAddress?: string;
}

export interface IContractPaymentAttempt {
  paymentType: EPaymentType.CONTRACT_PAYMENT | EPaymentType.SOLANA_PAYMENT;
  token: ECryptoCurrencySymbol;
  walletAddress?: string;
  walletName?: string;
}

export enum EPaymentDetailStatus {
  OPEN = 'OPEN',
  EXPIRED = 'EXPIRED',
  FIAT_CONFIRMED = 'FIAT_CONFIRMED',
  PENDING_CAPTURE = 'PENDING_CAPTURE', // for card payments: OPEN -> PENDING_CAPTURE -> CONFIRMED(CAPTURED)
  CONFIRMING = 'CONFIRMING',
  CONFIRMED = 'CONFIRMED',
  REFUNDED = 'REFUNDED',
  SETTLING = 'SETTLING',
  SETTLED = 'SETTLED',
  FAILED = 'FAILED',
  SETTLE_FAILED = 'SETTLE_FAILED',
  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
  MANUAL_INSPECTION_REQUIRED = 'MANUAL_INSPECTION_REQUIRED',
  CANCELLED = 'CANCELLED',
}

export interface IPaymentAttemptBase {
  id: string;
  type: EPaymentType;
  status: EPaymentDetailStatus;
  createdAt: number;
  confirmedAt?: number;
  updatedAt?: number;
  failureReason?: string; // from CKO
  billingEmail?: string;
  grossAmount?: string;
  feeAmount?: string;
  netAmount?: string;
  checkoutPaymentId?: string;
  // Sift
  score?: number;
  reasons?: Record<string, { name: string; value: string }>;
  // Risk Engine
  riskEngineTraceId?: string;
  riskEngineCheckLogs?: IRiskEngineCheckLog[];
}

export interface IPaymentAttempt extends IPaymentAttemptBase {
  attempt?: ICardPaymentAttempt | ITransferPaymentAttempt | IContractPaymentAttempt;
}

export enum EPayoutsStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  EXPIRED = 'EXPIRED',
  PROCESSING = 'PROCESSING',
}

export interface IOfframpDetail extends IBaseTimeStamp {
  id: string;
  network: ECryptoNetworkSymbol;
  token: ECryptoCurrencySymbol;
  amount: number;
  email: string;
  payByMerchant?: boolean;
  feePayByMerchant?: boolean;
  clientReferenceId?: string;
  status: EPayoutsStatus;
  livemode: boolean;
  customerCountry?: string;
  customerRegion?: string;
}
export interface IPaymentStat {
  amount: number;
  currency: ECryptoCurrencySymbol;
  year: number;
  month: number;
  day: number;
}
export interface IPaymentStatData {
  amount: number;
  stats: {
    yearly: Pick<IPaymentStat, 'amount' | 'currency' | 'year'>[];
    monthly: Pick<IPaymentStat, 'amount' | 'currency' | 'year' | 'month'>[];
    daily: IPaymentStat[];
  };
  nowDate?: string;
}

export interface ISendCrypto extends IBaseTimeStamp {
  id: string;
  merchantId: string;
  network: ECryptoNetworkSymbol;
  currency: ECryptoCurrencySymbol;
  amount: number; // should be converted to major unit form before display it
  recipientWalletAddress?: string;
  recipientEmail?: string;
  clientReferenceId?: string;
  status: EPayoutsStatus;
  lastError?: string;
  transactionHash?: string;
  approvedAt?: number;
  paidAt?: number;
}

interface IOperateWallets {
  solanaAddress: string;
  evmAddress: string;
}

export interface IConnection extends IBaseTimeStamp {
  id: 'string';
  receiverEmail: 'string';
  senderAccount: 'string';
  relationship: EConnectRelationships;
  status: EConnectsStatus;
}

export interface IInvition extends IBaseTimeStamp {
  receiverAccount: 'string';
  receiverEmail: 'string';
  senderAccount: 'string';
  senderEmail: 'string';
  relationship: EConnectRelationships;
}

export interface IIncomingRow extends IBaseTimeStamp {
  merchant?: {
    name: string;
    livemode: boolean;
    wallet: BeamoNS.IWalletAddress;
    merchantId: string;
  };
  amount: number;
  currency: ECryptoCurrencySymbol;
  status: EIncomingStatus;
  isRecurring?: boolean;
  billingEmail?: string;
  transactionFromAddress?: string;
  id: string;
  refundStatus: 'processing' | 'succeeded' | 'failed';
  refundId: string;
  [key: string]: TAnyType;
}

export interface IOutgoingRow extends IBaseTimeStamp {
  amount: number;
  status: 'PENDING' | 'EXPIRED' | 'CANCELED' | 'SUCCEEDED';
  id: string;
  email: string;
  token: ECryptoCurrencySymbol;
  network: ECryptoNetworkSymbol;
  merchantId?: string;
  [key: string]: TAnyType;
}

export type TSetMerchantNameResponse = Promise<BeamoNS.IMerchant[]>;
export type TSettlementReportType = 'MONTHLY' | 'REGULAR';
export type TOfframpReportType = 'MONTHLY' | 'WEEKLY';

export enum ESettlementReportsStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
}

export interface ISettlementReport extends IBaseTimeStamp {
  id: string;
  type: TSettlementReportType;
  amount: number;
  currency: ECryptoCurrencySymbol;
  year: number;
  month: number;
  numPayments: number;
  minPaidAt: number;
  maxPaidAt: number;
  status: ESettlementReportsStatus;
  // settlementAmount: number;
}

export enum EOfframpReportStatus {
  NEW = 'new',
  SUCCEEDED = 'succeeded',
  SKIPPED = 'skipped',
  FAILED = 'failed',
}

export interface IOfframpReport extends IBaseTimeStamp {
  id: string;
  amount: number;
  currency: ECryptoCurrencySymbol;
  year: number;
  month: number;
  numPayments: number;
  minPaidAt: number;
  maxPaidAt: number;
  status: EOfframpReportStatus;
}

export interface ISettlementSummary extends IBaseTimeStamp {
  balance: number;
  payableBalance: number;
  currency: ECryptoCurrencySymbol;
  nextSettlementTime: number;
  paidAtRange: number[] | undefined;
}

export interface ISettlementReportsQuery extends IPagingQuery {
  type: TSettlementReportType;
}

export type TStatementType = 'daily' | 'monthly' | 'weekly';
export type TStatementAccountType = 'payIn' | 'payOut';

export enum EStatementStatus {
  FINISHED = 'finished',
  PENDING = 'pending',
  SKIPPED = 'skipped',
}

export interface IStatement extends IBaseTimeStamp {
  id: string;
  type: TStatementType;
  amount: string;
  currency: ECryptoCurrencySymbol;
  minEffectiveAt: number;
  maxEffectiveAt: number;
  status: EStatementStatus;
}

export interface IStatementsQuery extends IPagingQuery {
  type: TStatementType;
  accountType: TStatementAccountType;
}

export interface IOfframpReportsQuery extends IPagingQuery {
  type: TOfframpReportType;
}

export type TIntegrationType = 'slack' | 'xero';
export type TIntegrationStatus = 'new' | 'connected' | 'expired' | 'disconnected';

// Disputes

// Third-party CKO data

export enum ECheckoutDisputeCategory {
  FRAUDULENT = 'fraudulent',
  UNRECOGNIZED = 'unrecognized',
  CANCELED_RECURRING = 'canceled_recurring',
  PRODUCT_SERVICE_NOT_RECEIVED = 'product_service_not_received',
  NOT_AS_DESCRIBED = 'not_as_described',
  CREDIT_NOT_ISSUED = 'credit_not_issued',
  DUPLICATE = 'duplicate',
  INCORRECT_AMOUNT = 'incorrect_amount',
  GENERAL = 'general',
}

export enum ECheckoutDisputeStatus {
  EVIDENCE_REQUIRED = 'evidence_required',
  EVIDENCE_SUBMITTED = 'evidence_submitted',
  EVIDENCE_UNDER_REVIEW = 'evidence_under_review',
  EVIDENCE_ACKNOWLEDGED_BY_SCHEME = 'evidence_acknowledged_by_scheme',
  RECEIVED = 'received',
  ARBITRATION_SENT_TO_SCHEME = 'arbitration_sent_to_scheme',
  // fund-loss final status
  EXPIRED = 'expired',
  ACCEPTED = 'accepted',
  ARBITRATION_LOST = 'arbitration_lost',
  LOST = 'lost',
  RESOLVED = 'resolved',
  // non-fund-loss final status
  CANCELED = 'canceled',
  WON = 'won',
  ARBITRATION_WON = 'arbitration_won',
}

export enum ECheckoutPaymentMethod {
  MASTERCARD = 'Mastercard',
  VISA = 'Visa',
  AMEX = 'Amex',
}
interface ICheckoutDispute {
  id: string; // "dsp_rbhwd2qrg13uhrp2newf";
  entity_id: string; // "ent_wxglze3wwywujg4nna5fb7ldli";
  sub_entity_id: string; // "ent_uzm3uxtssvmuxnyrfdffcyjxeu";
  category: ECheckoutDisputeCategory;
  status: ECheckoutDisputeStatus;
  amount: number;
  currency: string; // "GBP";
  reason_code: string; // "10.4";
  payment_id: string; // "pay_88cb4e671m1da22e9bbbyx";
  payment_action_id: string; // "act_mbabizu24mvu3mela5njyhpit4";
  payment_reference: string; // "th7zxa1kcnqmes8";
  payment_arn: string; // "74548998294293193445538";
  payment_mcc: string; // "5021";
  payment_method: ECheckoutPaymentMethod; // "VISA";
  evidence_required_by: string; // "2018-08-22T00:00:00Z";
  received_on: string; // "2018-08-01T01:15:56Z";
  last_update: string; // "2018-08-12T04:15:56Z";
  resolved_reason: string; // "already_refunded";
}

export enum EDisputeStatus {
  NEW = 'new',
  ONGOING = 'ongoing',
  WON = 'won',
  LOST = 'lost',
  CANCELED = 'canceled',
  MANUALLY_CANCELED = 'manuallyCanceled',
}

export enum EDisputeType {
  CHECKOUT = 'checkout',
}

export enum EDisputeEvidenceTypes {
  // For Ops
  DISPUTE_QUESTIONNAIRE = 'dispute_questionnaire',
  // For Stake
  PURCHASE_RECORD = 'purchase_record',
  USAGE_RECORD = 'usage_record',
  USER_CONVERSATION = 'user_conversation',
}
export interface IDisputeEvidence {
  id?: string;
  evidenceType: EDisputeEvidenceTypes;
  evidenceImageUrls: string[];
  uploaderEmail: string;
  disputeId: string;
}

interface IDisputeCheckoutPayment {
  id: string;
  cardholderName?: string;
  bin: string;
  last4: string;
  paymentRequestedDatetime: string;
  paymentProcessedDatetime: string;
}

export interface IDisputesData {
  id: string;
  type: EDisputeType;
  email: string;
  status: EDisputeStatus;
  livemode: boolean;
  sourceUpdatedAt: number;
  checkedAt: number;
  createdAt: number;
  updatedAt: number;
  clientReferenceId: string;
  data?: ICheckoutDispute;
  disputeEvidences?: IDisputeEvidence[];
  checkoutPayment?: IDisputeCheckoutPayment;
  relatedDisputeIds?: string[];
  imageSignedUrls?: Record<string, string>;
}

export interface IRefundParams {
  pageId: string;
  data: {
    reason?: string;
  };
}

export interface ISorter {
  orderByField: 'createdAt' | 'updatedAt' | 'amount';
  orderByDirection: 'ASC' | 'DESC';
}

export interface IGetDisputesQuery {
  pagination: Required<IPagingQuery>;
  filters?: {
    // This refers to CKO status
    disputeStatuses?: ECheckoutDisputeStatus[];
    // This refers to CKO dispute id
    disputeIds?: string[];
    clientReferenceIds?: string[];
    emails?: string[];
  };
  sorters?: ISorter;
}

export interface IGetDisputesResponse {
  disputes: IDisputesData[];
  count: number;
}

export interface IDisputeNote {
  createdAt?: string;
  id?: string;
  addedBy: string;
  content: string;
  entityId: string; // for example: this can be paymentId, disputeId, etc.
}

export interface IAddDisputeNoteRequest {
  note: IDisputeNote;
}

export interface IGetDisputeNotesRequest {
  disputeId: string;
}

export interface IGetDisputeNotesResponse {
  notes: IDisputeNote[];
}

interface IUserAccountDetails {
  id: string;
  authUid: string;
  email: string;
  createdAt: number;
  wallet?: PaymentPage.IWallet;
  customWallet?: PaymentPage.IWallet;
  updatedAt?: number;
  kyc?: { status: EKycStatus };
}

export interface IUserKyc {
  state?: string;
  city?: string;
  country?: string;
  dob?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

interface IOnboardedUserDetails {
  onboarded: true;
  accountDetails: IUserAccountDetails;
  kycDetails?: IUserKyc;
}

interface IUnonboardedUserDetails {
  onboarded: false;
}

export const SIFT_USER_FIELDS = [
  'emailAddressAge',
  'numUsersWithSameDevice',
  'accountAge',
  'changesInPaymentMethodsWeekly',
  'uniqueBillingNamesMonthly',
] as const;

export type TSiftUserField = (typeof SIFT_USER_FIELDS)[number];

export type TSiftUserDetails = Partial<Record<TSiftUserField, number>>;

export interface IGetUserDetailsResponse {
  // We get this from the latest payment date
  lastActivityDatetime?: number;
  userDetails: IOnboardedUserDetails | IUnonboardedUserDetails;
  siftDetails?: TSiftUserDetails;
}

export interface IGetUserPaymentPagesByEmailResponse {
  paymentPages: IPaymentPage[];
  hasMore: boolean;
}

export enum EPaymentEventTypes {
  // payment page events
  PAYMENT_PAGE_CREATED = 'PAYMENT_PAGE_CREATED',
  PAYMENT_PAGE_STATUS_CHANGE = 'PAYMENT_PAGE_STATUS_CHANGE',
  // payment events
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_STATUS_CHANGED = 'PAYMENT_STATUS_CHANGED',
  // webhooks (card payments)
  WEBHOOK_SENT = 'WEBHOOK_SENT',
}

// Payment Events
// Metadata fields
interface IPaymentPageStatusChangeEvent {
  eventType: EPaymentEventTypes.PAYMENT_PAGE_STATUS_CHANGE;
  metadata: {
    beforeStatus: EPaymentPageStatus;
    afterStatus: EPaymentPageStatus;
  };
}

interface IPaymentStatusChangeEvent {
  eventType: EPaymentEventTypes.PAYMENT_STATUS_CHANGED;
  metadata: {
    beforeStatus: EPaymentDetailStatus;
    afterStatus: EPaymentDetailStatus;
  };
}

interface IWebhookSentEvent {
  eventType: EPaymentEventTypes.WEBHOOK_SENT;
  metadata: {
    pageId: string;
    currency: EPresentmentCurrency;
    amount: number;
    clientReferenceId?: string;
    status: EPaymentPageStatus;
    flexibleAmount?: {
      requested: number;
      received: number;
    };
    payment?: {
      currency: string;
      requested: string;
      received: string;
      receivedPercentage: string;
    };
  };
}

export enum ECheckoutWebhookType {
  AUTHENTICATION_STARTED = 'authentication_started',
  AUTHENTICATION_ATTEMPTED = 'authentication_attempted',
  AUTHENTICATION_APPROVED = 'authentication_approved',
  AUTHENTICATION_FAILED = 'authentication_failed',
  AUTHENTICATION_EXPIRED = 'authentication_expired',
  PAYMENT_DECLINED = 'payment_declined',
  PAYMENT_VOIDED = 'payment_voided',
  PAYMENT_VOID_DECLINED = 'payment_void_declined',
  PAYMENT_APPROVED = 'payment_approved',
  PAYMENT_CAPTURED = 'payment_captured',
  PAYMENT_AUTHENTICATION_FAILED = 'payment_authentication_failed',
  PAYMENT_REFUNDED = 'payment_refunded',
  DISPUTE_RECEIVED = 'dispute_received',
  DISPUTE_EVIDENCE_REQUIRED = 'dispute_evidence_required',
  DISPUTE_EVIDENCE_SUBMITTED = 'dispute_evidence_submitted',
  DISPUTE_EVIDENCE_UNDER_REVIEW = 'dispute_evidence_under_review',
  DISPUTE_EVIDENCE_ACKNOWLEDGED_BY_SCHEME = 'dispute_evidence_acknowledged_by_scheme',
  DISPUTE_ARBITRATION_SENT_TO_SCHEME = 'dispute_arbitration_sent_to_scheme',
  // fund-loss final status
  DISPUTE_EXPIRED = 'dispute_expired',
  DISPUTE_ACCEPTED = 'dispute_accepted',
  DISPUTE_ARBITRATION_LOST = 'dispute_arbitration_lost',
  DISPUTE_LOST = 'dispute_lost',
  DISPUTE_RESOLVED = 'dispute_resolved',
  // non-fund-loss final status
  DISPUTE_CANCELED = 'dispute_canceled',
  DISPUTE_WON = 'dispute_won',
  DISPUTE_ARBITRATION_WON = 'dispute_arbitration_won',
  // Frauds
  FRAUD_REPORT = 'fraud_reported',
}

type TPaymentEventMetadataType =
  | {
      eventType: Exclude<
        EPaymentEventTypes,
        | EPaymentEventTypes.PAYMENT_PAGE_STATUS_CHANGE
        | EPaymentEventTypes.WEBHOOK_SENT
        | EPaymentEventTypes.PAYMENT_STATUS_CHANGED
      >;
      metadata?: Record<string, string>;
    }
  | IPaymentPageStatusChangeEvent
  | IWebhookSentEvent
  | IPaymentStatusChangeEvent;

type TPaymentEventEntity = {
  pageId?: string;
  paymentId?: string;
};

export type TPaymentEvent = IBaseModel & TPaymentEventMetadataType & TPaymentEventEntity;

export interface IGetPaymentEventsByPageIdResponse {
  paymentEvents: TPaymentEvent[];
}

export interface IGetPaymentEventsByPaymentIdResponse {
  paymentEvents: TPaymentEvent[];
}

// API interface
export interface IDashboardApi {
  // Common
  generateSignedUrl: (params: IGenerateSignedUrlRequest) => Promise<IGenerateSignedUrlResponse>;
  createInvoice: (params: IInvoiceSub) => Promise<IInvoice>;
  createProduct: (params: IProductSub) => Promise<IProduct>;
  createPaymentLink: (params: IPaymentLinkSub) => Promise<IPaymentLink>;
  createOfframpRequestLink: (params: { amount: string; email: string }) => Promise<{ id: string; url: string }>;
  getInvoice: (params: { invoiceId: string }) => Promise<IInvoice>;
  retrieveInvoices: (params: IPagingQuery) => Promise<IInvoice[]>;
  retrievePaymentLinks: (params: IPagingQuery) => Promise<IPaymentLink[]>;

  createSecret: () => Promise<{
    secrets: ISecret[];
    new: {
      id: string;
      secret: string;
    };
  }>;
  getSecretList: () => Promise<ISecret[]>;
  apiDisableSecret: (secret: string) => Promise<ISecret[]>;
  setMerchantName: (params: { name: string }) => TSetMerchantNameResponse;
  setWebhookURL: (params: { webhook: string }) => Promise<TAnyType>;
  retrievePaymentPage: (params: { pageId: string }) => Promise<IPaymentPage>;
  refundPaymentPage: (params: IRefundParams) => Promise<TAnyType>;
  getPaymentsByPageId: (params: { pageId: string }) => Promise<IPaymentDetail[]>;
  getPaymentAttemptsByPageId: (params: {
    pageId: string;
    pagination?: IPagingQuery;
  }) => Promise<{ paymentAttempts: IPaymentAttempt[]; hasMore: boolean }>;
  getPaymentAttempt: (params: { paymentId: string }) => Promise<{ paymentAttempt: IPaymentAttempt }>;
  retrievePaymentStats: (params: { limit: number }) => Promise<IPaymentStatData>;
  retrievePaymentIncoming: (params: {
    limit: number;
    offset: number;
    status: EIncomingStatus;
    email?: string;
    id?: string;
  }) => Promise<{
    countByStatus: {
      UNPAID: number;
      EXPIRED: number;
      PAID: number;
    };
    count: number;
    results: Array<IIncomingRow>;
  }>;
  retrievePaymentOutgoing: (params: {
    limit: number;
    offset: number;
    status: EOutgoingStatus;
    email?: string;
    id?: string;
  }) => Promise<{
    countByStatus: {
      PENDING: number;
      EXPIRED: number;
      CANCELED: number;
      SUCCEEDED: number;
    };
    count: number;
    results: Array<IOutgoingRow>;
  }>;
  retrieveSendCryptos: (params: IPagingQuery) => Promise<ISendCrypto[]>;
  retrieveWebhookSecret: () => Promise<string>;
  retrieveOperateWallets: () => Promise<IOperateWallets>;
  retrieveConnections: () => Promise<Array<IConnection>>;
  createConnections: (params: {
    emails: Array<string>;
    relationship: EConnectRelationships;
  }) => Promise<Array<IInvition>>;
  removeConnections: (params: { connectId: string }) => Promise<TAnyType>;
  setMerchantId: (id: string) => void;
  getOfframpDetail: (id: string) => Promise<IOfframpDetail>;
  getSettlementReports: (params: ISettlementReportsQuery) => Promise<IArrayData<ISettlementReport>>;
  getStatements: (params: IStatementsQuery) => Promise<IArrayData<IStatement>>;
  getStatementDownloadUrl(id: string): Promise<{ url: string }>;
  getSettlementSummary: () => Promise<ISettlementSummary>;
  getOfframpSettings: () => Promise<{
    network: ECryptoNetworkSymbol;
    token: ECryptoCurrencySymbol;
    address: string;
    coldWalletAddress?: string;
  }>;
  getOfframpReports: (params: IOfframpReportsQuery) => Promise<IArrayData<IOfframpReport>>;
  getSettlementReportFile: (id: string) => Promise<{ detailReportUrl: string }>;
  getSettlementSummaryReportFile: (id: string) => Promise<{ summaryReportUrl: string }>;
  getOfframpReportFile: (id: string) => Promise<{ detailReportUrl: string }>;
  getIntegrations: () => Promise<
    [
      {
        id?: string;
        type: TIntegrationType;
        status: TIntegrationStatus;
        tenantInfo?: {
          id: string;
          name: string;
        };
      },
    ]
  >;
  getConnectUrl: (type: TIntegrationType) => Promise<{ url: string }>;
  disconnectIntegration: (id: string) => Promise<void>;
  // Disputes
  getDisputes: (params: IGetDisputesQuery) => Promise<{ disputes: IDisputesData[]; count: number }>;
  upsertDisputeEvidence: (params: { disputeEvidences: IDisputeEvidence[] }) => Promise<void>;
  addDisputeNote: (params: IAddDisputeNoteRequest) => Promise<void>;
  getDisputeNotes: (params: IGetDisputeNotesRequest) => Promise<IGetDisputeNotesResponse>;
  // Fraud Reports
  getFraudReports: (params?: IGetFraudReportsRequest) => Promise<IGetFraudReportsResponse>;
  getFraudReport: (params: IGetFraudReportRequest) => Promise<IGetFraudReportResponse>;
  getUserDetails: (params: { email: string }) => Promise<IGetUserDetailsResponse>;
  getUserPaymentPagesByEmail: (params: {
    email: string;
    pagination?: IPagingQuery;
  }) => Promise<IGetUserPaymentPagesByEmailResponse>;
  getPaymentEventsByPageId: (params: {
    pageId: string;
    pagination?: IPagingQuery;
  }) => Promise<IGetPaymentEventsByPageIdResponse>;
  getPaymentEventsByPaymentId: (params: {
    pageId: string;
    paymentId: string;
    pagination?: IPagingQuery;
  }) => Promise<IGetPaymentEventsByPaymentIdResponse>;
  getSettlementByPageId: (params: { pageId: string }) => Promise<{ settlement: TPaymentPageSettlement }>;
  getPaymentPageNft: (params: { pageId: string }) => Promise<{ nft?: TPaymentPageNft }>;
}

let merchantId = '';

const createDashboardApi = (instance: Axios): IDashboardApi => {
  instance.interceptors.request.use(async (config) => {
    await merchantResolvedLock.promise;
    config.headers!['x-beamo-merchant'] = merchantId;
    return config;
  });

  return {
    // Common
    generateSignedUrl(params) {
      // Encode content type as it contains '/'
      return instance.get(`/files/upload/get_signed_url?fileType=${encodeURIComponent(params.fileType)}`);
    },
    createInvoice(params: IInvoiceSub) {
      return instance.post(`/invoices`, params);
    },
    createProduct(params: IProductSub) {
      return instance.post(`/products`, params);
    },
    createPaymentLink(params: IPaymentLinkSub) {
      return instance.post(`/payment_links`, params);
    },
    createOfframpRequestLink(params: { amount: string; email: string }) {
      return instance.post(`/offramp`, {
        ...params,
        network: 'polygon',
        token: 'usdc',
        clientReferenceId: String(Math.random()),
        // required for offramp merchant:
        // capabilities.offramp.escrows.POLYGON = 'operate_wallet'
        // capabilities.offramp.payByMerchant = 'ENABLED'
        settings: {
          payByMerchant: true,
          feePayByMerchant: true,
        },
        customer: {
          country: 'US',
        },
        successReturnUrl: 'https://example.com/success',
        failReturnUrl: 'https://example.com/fail',
      });
    },
    getInvoice(params: { invoiceId: string }) {
      return instance.get(`/invoices/client/${params.invoiceId}`);
    },
    retrieveInvoices(params: IPagingQuery) {
      return instance.get(`/invoices`, { params });
    },
    retrievePaymentLinks(params: IPagingQuery) {
      return instance.get(`/payment_links`, { params });
    },
    createSecret() {
      return instance.post(`/secrets`);
    },
    getSecretList() {
      return instance.get(`/secrets`);
    },
    apiDisableSecret(secret) {
      return instance.post(`/secrets/${secret}/disable`);
    },
    async setMerchantName(params) {
      return instance.post(`/merchants/${merchantId}`, params);
    },
    async setWebhookURL(params) {
      return instance.post(`/webhooks/${merchantId}`, params);
    },
    retrievePaymentPage({ pageId }) {
      return instance.get(`/payment_pages/${pageId}`);
    },
    refundPaymentPage(params: IRefundParams) {
      return instance.post(`/payment_pages/${params.pageId}/refund`, params.data);
    },
    getPaymentsByPageId({ pageId }) {
      return instance.get(`/payment_pages/${pageId}/payments`);
    },
    getPaymentAttemptsByPageId({ pageId, pagination }) {
      return instance.get(`/payment_pages/${pageId}/payment_details`, { params: { pagination } });
    },
    getPaymentAttempt({ paymentId }) {
      return instance.get(`/payment_pages/payment/${paymentId}/payment_details`);
    },
    getPaymentEventsByPageId({ pageId, pagination }) {
      return instance.get(`/payment_pages/${pageId}/payment_events`, { params: pagination });
    },
    getPaymentEventsByPaymentId({ pageId, paymentId, pagination }) {
      return instance.get(`/payment_pages/${pageId}/payment_events/${paymentId}`, { params: pagination });
    },
    getSettlementByPageId({ pageId }) {
      return instance.get(`/payment_pages/${pageId}/settlement`);
    },
    retrievePaymentStats(params) {
      // // don't delete the mock data
      // return Promise.resolve({
      //   status: 'SUCCEEDED',
      //   data: {
      //     amount: 290,
      //     stats: mockStatsData(),
      //     // nowDate: '2023-04-12',
      //   },
      // });
      return instance.get(`/payment_stats`, { params });
    },
    async retrievePaymentIncoming(params) {
      return instance.get(`/transactions/in`, { params: trimObjValues(params) });
    },
    async retrievePaymentOutgoing(params) {
      return instance.get(`/transactions/out`, { params: trimObjValues(params) });
    },
    retrieveSendCryptos(params) {
      return instance.get(`/send_cryptos`, { params });
    },

    retrieveWebhookSecret() {
      return instance.get(`/webhooks/secret`);
    },
    retrieveOperateWallets() {
      return instance.get(`/operate_wallets`);
    },
    retrieveConnections() {
      return instance.get(`/connects/members`);
    },
    createConnections(params) {
      return instance.post(`/connects/invitations`, params);
    },
    removeConnections(params) {
      return instance.post(`/connects/requests/${params.connectId}/revoke`);
    },
    setMerchantId(id) {
      merchantId = id;
      merchantResolvedLock.resolve();
    },
    getOfframpDetail(id) {
      return instance.get(`/offramp/${id}`);
    },
    getSettlementReports(params) {
      return instance.get(`/settlement_reports`, { params });
    },
    getOfframpReports(params) {
      return instance.get(`/offramp_reports`, { params });
    },
    getSettlementSummary() {
      return instance.get(`/settlement_reports/summary`);
    },
    getStatements(params) {
      return instance.get('/reports/statements', { params });
    },
    getStatementDownloadUrl(id) {
      return instance.get(`/reports/statements/${id}/download`);
    },
    getOfframpSettings() {
      return instance.get(`/merchants/settings/offramp`);
    },
    getOfframpReportFile(id) {
      return instance.get(`/offramp_reports/${id}/download`);
    },
    getSettlementReportFile(id) {
      return instance.get(`/settlement_reports/${id}/download`);
    },
    getSettlementSummaryReportFile(id) {
      return instance.get(`/settlement_reports/summary/${id}/download`);
    },
    getIntegrations() {
      return instance.get('/integrations');
    },
    getConnectUrl(type) {
      return instance.get(`/integrations/connect?type=${type}`);
    },
    disconnectIntegration(id) {
      return instance.post(`/integrations/${id}/disconnect`);
    },
    getDisputes(params) {
      return instance.get('/disputes', { params });
    },
    upsertDisputeEvidence(params) {
      return instance.post('/disputes/upsert_dispute_evidence', params);
    },
    addDisputeNote(params) {
      return instance.post('/disputes/notes', params);
    },
    getDisputeNotes(params) {
      return instance.get(`/disputes/notes/${params.disputeId}`);
    },
    getFraudReports(params) {
      return instance.get('/fraud_reports', { params });
    },
    getFraudReport(params) {
      return instance.get(`/fraud_reports/${params.fraudReportId}`);
    },
    getUserDetails(params) {
      return instance.get(`/customers/${params.email}`);
    },
    getUserPaymentPagesByEmail({ pagination, email }) {
      return instance.get(`/customers/${email}/payment_pages`, { params: { pagination } });
    },
    getPaymentPageNft(params) {
      return instance.get(`/payment_pages/${params.pageId}/nft`);
    },
  };
};

// use a new instance to add merchant header
export const dashboardApi = createDashboardApi(createBaseAxios({ needAuthToken: true }));
